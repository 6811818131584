import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { ArrowBack, Settings } from '@material-ui/icons';

const styles = theme => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  categoryHeaderPrimary: {
    color: theme.palette.text.primary
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: theme.palette.text.primary,
    '&:hover,&:focus': {
      backgroundColor: 'var(--color-depth8)'
    }
  },
  itemCategory: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    fontSize: 24,
    color: theme.palette.text.primary,

    '&:hover,&:focus': {
      backgroundColor: 'var(--color-depth8)'
    }
  },
  itemActiveItem: {
    color: theme.palette.primary.main,
    backgroundColor: 'var(--color-depth4)'
  },
  itemPrimary: {
    fontSize: 'inherit'
  },
  itemIcon: {
    minWidth: 'auto',
    color: 'inherit',
    marginRight: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(2)
  }
});

const categories = [
  {
    id: 'Allgemein',
    product: 'general',
    children: [
      {
        id: 'Allgemein',
        icon: <Settings color='inherit' />,
        slug: 'general'
      }
    ]
  }
];

const LeftNav = props => {
  const { classes, history, match, ...other } = props;

  const { feature } = match.params;

  return (
    <Drawer variant='permanent' {...other}>
      <List disablePadding style={{ background: 'var(--color-depth2)' }}>
        <ListItem
          className={clsx(classes.header, classes.item, classes.itemCategory)}
        >
          Kontoeinstellungen
        </ListItem>
        <ListItem
          button
          onClick={() => history.goBack()}
          className={clsx(classes.item, classes.itemCategory)}
        >
          <ListItemIcon className={classes.itemIcon}>
            <ArrowBack />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary
            }}
          >
            Zurück
          </ListItemText>
        </ListItem>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, slug }) => (
              <ListItem
                key={childId}
                button
                onClick={() => history.push(slug)}
                className={clsx(
                  classes.item,
                  slug === feature && classes.itemActiveItem
                )}
              >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
};

export default withStyles(styles)(LeftNav);
