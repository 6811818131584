import React, { useState, useContext, useEffect } from 'react';
import { DashboardContext } from 'Contexts/DashboardContext';
import {
  Dialog,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  RadioGroup,
  Radio,
  Button,
  IconButton
} from '@material-ui/core';
import { Folder, Add, Close } from '@material-ui/icons';

const viewSchema = {
  id: 'new',
  filter: {
    occassion: [],
    space: []
  },
  name: '',
  standardSpace: ''
};

const EditViews = () => {
  const [views, setviews] = useState([]);
  const [currentView, setcurrentView] = useState({});
  const [occassions, setoccassions] = useState([]);
  const [spaces, setspaces] = useState([]);

  const { restaurantData, updateViews, editViews, seteditViews } = useContext(
    DashboardContext
  );

  useEffect(() => {
    if (restaurantData.views) {
      setviews(restaurantData.views);
      if (restaurantData.views.length) {
        setcurrentView(restaurantData.views[0]);
      }
    }

    if (restaurantData.spaces) {
      setspaces(restaurantData.spaces);
    }

    if (restaurantData.occassions) {
      setoccassions(restaurantData.occassions);
    }
  }, [restaurantData]);

  const changeFilter = (id, filter = '') => () => {
    if (currentView.filter[filter].includes(id)) {
      return setcurrentView({
        ...currentView,
        filter: {
          ...currentView.filter,
          [filter]: currentView.filter[filter].filter(x => x !== id)
        }
      });
    } else {
      return setcurrentView({
        ...currentView,
        filter: {
          ...currentView.filter,
          [filter]: [...currentView.filter[filter], id]
        }
      });
    }
  };

  return (
    <Dialog
      onClose={() => seteditViews(false)}
      aria-labelledby="edit-views-title"
      open={editViews}
      maxWidth="md"
      style={{ flexDirection: 'column' }}
    >
      <Box
        style={{
          display: 'flex',
          width: ' 100%',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        id="edit-views-title"
      >
        <DialogTitle style={{ flex: 1 }}>Ansichten bearbeiten</DialogTitle>
        <IconButton onClick={() => seteditViews(false)}>
          <Close size="small" />
        </IconButton>
      </Box>
      <DialogContent dividers style={{ padding: 0 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={5}>
            <List>
              {views.map(view => (
                <ListItem
                  selected={currentView && currentView.id === view.id}
                  button
                  onClick={() => setcurrentView(view)}
                  key={view.id}
                >
                  <ListItemAvatar style={{ minWidth: 46 }}>
                    <Avatar style={{ width: 30, height: 30 }}>
                      <Folder />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={view.name} />
                </ListItem>
              ))}
              <ListItem
                selected={currentView && currentView.id === 'new'}
                button
                onClick={() => setcurrentView(viewSchema)}
              >
                <ListItemAvatar style={{ minWidth: 46 }}>
                  <Avatar style={{ width: 30, height: 30 }}>
                    <Add />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Neue Ansicht erstellen" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={7}>
            {currentView.id && (
              <Box style={{ padding: 10 }}>
                <TextField
                  name="name"
                  value={currentView.name}
                  label="Titel"
                  onChange={e =>
                    setcurrentView({ ...currentView, name: e.target.value })
                  }
                  fullWidth
                  style={{ marginBottom: 20 }}
                  color="primary"
                />

                <Box style={{ display: 'flex', marginBottom: 20 }}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Anlässe</FormLabel>
                    <FormGroup>
                      {occassions.map(o => (
                        <FormControlLabel
                          key={o.id}
                          control={
                            <Checkbox
                              checked={currentView.filter.occassion.includes(
                                o.id
                              )}
                              onChange={changeFilter(o.id, 'occassion')}
                            />
                          }
                          label={o.title}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>

                  <FormControl component="fieldset">
                    <FormLabel component="legend">Bereiche</FormLabel>
                    <FormGroup>
                      {spaces.map(s => (
                        <FormControlLabel
                          key={s.id}
                          control={
                            <Checkbox
                              checked={currentView.filter.space.includes(s.id)}
                              onChange={changeFilter(s.id, 'space')}
                            />
                          }
                          label={s.title}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Box>
                <FormControl
                  component="fieldset"
                  style={{ display: 'block', marginBottom: 20 }}
                >
                  <FormLabel component="legend">Standard Bereich</FormLabel>
                  <RadioGroup
                    value={currentView.standardSpace}
                    onChange={e =>
                      setcurrentView({
                        ...currentView,
                        standardSpace: e.target.value
                      })
                    }
                  >
                    {spaces.map(s => (
                      <FormControlLabel
                        key={s.id}
                        control={<Radio />}
                        value={s.id}
                        label={s.title}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => updateViews(currentView)}
                >
                  Speichern
                </Button>

                <Button onClick={() => updateViews(currentView, 'delete')}>
                  Löschen
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditViews;
