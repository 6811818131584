import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  InputAdornment
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const ChangeEmailModal = ({
  open,
  onClose = () => {},
  onSubmit = () => {}
}) => {
  const [pwd, setPwd] = useState({ show: false, value: '' });
  const [newEmail, setNewEmail] = useState({ error: false, value: '' });

  const checkAndSubmit = () => {
    const emailRegex = new RegExp(
      /^[a-z0-9](?!.*?[^\na-z0-9]{2})[^\s@]+@[^\s@]+\.[^\s@]+[a-z0-9]$/
    );
    if (!emailRegex.test(newEmail.value)) {
      setNewEmail({ ...newEmail, error: true });
      return;
    }
    onSubmit(pwd.value, newEmail.value);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>E-Mail Adresse ändern</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          type={pwd.show ? 'text' : 'password'}
          label='Passwort'
          name='password'
          value={pwd.value}
          onChange={e => setPwd({ ...pwd, value: e.target.value })}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position='end'
                onClick={() => setPwd({ ...pwd, show: !pwd.show })}
              >
                {pwd.show ? <Visibility /> : <VisibilityOff />}
              </InputAdornment>
            )
          }}
        />
        <TextField
          fullWidth
          type='email'
          label='Neue Email Adresse'
          name='email'
          value={newEmail.value}
          onChange={e => setNewEmail({ error: false, value: e.target.value })}
          error={newEmail.error}
          helperText={newEmail.error ? 'Falsches E-Mail-Format' : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Abbrechen
        </Button>
        <Button onClick={checkAndSubmit} color='primary'>
          Fortfahren
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeEmailModal;
