import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  InputAdornment
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const NewPasswordModal = ({
  open = false,
  onClose = () => {},
  onSubmit = () => {},
  setAlert = () => {}
}) => {
  const [oldPwd, setOldPwd] = useState({
    value: '',
    show: false,
    name: 'oldPwd',
    label: 'Aktuelles Passwort'
  });
  const [newPwd, setNewPwd] = useState({
    value: '',
    show: false,
    name: 'newPwd',
    label: 'Neues Passwort'
  });
  const [confirmPwd, setConfirmPwd] = useState({
    value: '',
    show: false,
    name: 'confirmPwd',
    label: 'Passwort bestätigen'
  });

  const pwdArr = [oldPwd, newPwd, confirmPwd];

  const handleTextChange = e => {
    const { name, value } = e.target;
    switch (name) {
      case 'oldPwd':
        setOldPwd({ ...oldPwd, value });
        break;
      case 'newPwd':
        setNewPwd({ ...newPwd, value });
        break;
      case 'confirmPwd':
        setConfirmPwd({ ...confirmPwd, value });
        break;
      default:
        break;
    }
  };

  const handleShowChange = pwdName => {
    switch (pwdName) {
      case 'oldPwd':
        setOldPwd({ ...oldPwd, show: !oldPwd.show });
        break;
      case 'newPwd':
        setNewPwd({ ...newPwd, show: !newPwd.show });
        break;
      case 'confirmPwd':
        setConfirmPwd({ ...confirmPwd, show: !confirmPwd.show });
        break;
      default:
        break;
    }
  };

  const checkAndSubmit = () => {
    if (newPwd.value !== confirmPwd.value) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'New password and confirmation do not fit.'
      });
      console.log('Different');
    } else if (oldPwd.value === newPwd.value) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'New password should be different from actual one.'
      });
    } else {
      onSubmit(oldPwd.value, newPwd.value);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Passwort ändern</DialogTitle>
      <DialogContent>
        {pwdArr.map((pwd, i) => (
          <TextField
            fullWidth
            key={i}
            type={pwd.show ? 'text' : 'password'}
            label={pwd.label}
            name={pwd.name}
            value={pwd.value}
            onChange={handleTextChange}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position='end'
                  onClick={() => handleShowChange(pwd.name)}
                >
                  {pwd.show ? <Visibility /> : <VisibilityOff />}
                </InputAdornment>
              )
            }}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Abbrechen
        </Button>
        <Button onClick={checkAndSubmit} color='primary'>
          Fortfahren
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewPasswordModal;
