import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker.js';
import { registerObserver } from 'react-perf-devtool';

var options = {
  timeout: 12000 // Load the extension after 12 sec.
};

function callback(measures) {
  // do something with the measures
}

registerObserver(options, callback);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
