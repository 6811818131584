import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Paper,
  Typography,
  Avatar,
  Divider,
  Button,
  Snackbar,
  IconButton,
  TextField
} from '@material-ui/core';
import {
  EditRounded,
  CheckCircleOutlineRounded,
  CloseRounded
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { FirebaseContext } from 'Contexts/FirebaseContext';
import NewPasswordModal from './NewPasswordModal';
import ChangeEmailModal from './ChangeEmailModal';

const useStyles = makeStyles({
  header: {
    minHeight: '120px',
    backgroundColor: 'var(--color-depth2)',
    padding: '0 24px',
    paddingTop: '64px'
  },
  userInfo: {
    width: '80%',
    margin: 'auto',
    padding: '20px',
    marginTop: '20px'
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 10
  },
  avatar: {
    width: '100px',
    height: '100px',
    backgroundColor: 'var(--color-primary)',
    fontSize: '2rem',
    marginRight: '2vw'
  },
  flex: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-start'
  },
  editButton: {
    fontSize: '16px',
    color: 'var(--color-textHinted)'
  },
  editImage: {
    position: 'absolute',
    bottom: '0px',
    right: '10px',
    color: 'var(--color-textHinted)',
    cursor: 'pointer'
  },
  deleteImage: {
    position: 'absolute',
    top: '0px',
    right: '5px',
    color: 'var(--color-textHinted)',
    cursor: 'pointer'
  },
  divider: {
    margin: '20px -20px'
  },
  restaurantList: {
    paddingLeft: 20
  },
  restaurantLine: {
    textTransform: 'capitalize'
  },
  role: {
    color: 'var(--color-textHinted)'
  }
});

const GeneralAccountSettings = props => {
  const classes = useStyles();
  const {
    data,
    changeUserPassword,
    sendPasswordResetEmail,
    changeUserDisplayName,
    changeUserEmail,
    uploadProfilePicture,
    deleteProfilePicture
  } = useContext(FirebaseContext);
  const [user, setUser] = useState({});
  const [openPwdModal, setOpenPwdModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [changeName, setChangeName] = useState({
    open: false,
    value: ''
  });
  const [alert, setAlert] = useState({
    open: false,
    message: 'Oops',
    severity: 'error'
  });

  useEffect(() => {
    if (data.user) {
      setUser(data.user);
      setChangeName({ ...changeName, value: data.user.displayName });
    }
  }, [data.user]);

  const getInitials = (userName = '') => {
    if (!userName) return '';
    const wordsArr = userName.split(' ');
    let initials = '';
    wordsArr.forEach(word => (initials += word.split('')[0].toUpperCase()));
    return initials;
  };

  const handleChangePassword = async (oldPwd, newPwd) => {
    const answer = await changeUserPassword(
      user.uid,
      user.email,
      oldPwd,
      newPwd
    );
    console.log(answer);
    if (answer.error) {
      setAlert({
        open: true,
        severity: 'error',
        message: answer.error.message
      });
    } else {
      setAlert({
        open: true,
        severity: 'success',
        message: 'The password has been successfully updated.'
      });
      setOpenPwdModal(false);
    }
  };

  const handleResetPwd = async () => {
    const answer = await sendPasswordResetEmail(user.uid, user.email, true);
    console.log(answer);
    if (answer.error) {
      setAlert({
        open: true,
        severity: 'error',
        message: answer.error.message
      });
    } else {
      setAlert({
        open: true,
        severity: 'success',
        message: `A reset link has been sent to ${user.email}`
      });
    }
  };

  const handleNameChange = async () => {
    if (changeName.value !== user.displayName) {
      const answer = await changeUserDisplayName(user.uid, changeName.value);
      if (answer.success) {
        setUser({ ...user, displayName: changeName.value });
        setAlert({
          open: true,
          message: 'Your name ha been changed',
          severity: 'success'
        });
      } else {
        setAlert({
          open: true,
          severity: 'error',
          message: 'Opps, something went wrong, please try again'
        });
      }
    }
    setChangeName({ ...changeName, open: false });
  };

  const handleEmailChange = async (pwd, newEmail) => {
    console.log('email change');
    const answer = await changeUserEmail(user.uid, user.email, pwd, newEmail);
    console.log(answer);
    if (answer.error) {
      setAlert({
        open: true,
        severity: 'error',
        message: answer.error.message
      });
    } else {
      setAlert({
        open: true,
        severity: 'success',
        message: `Your email is now set up to ${newEmail}`
      });
      setUser({ ...user, email: newEmail });
      setOpenEmailModal(false);
    }
  };

  const handlePictureChange = async e => {
    const file = Array.from(e.target.files)[0];
    const answer = await uploadProfilePicture(file, user.uid);
    console.log(answer);
    if (answer.error) {
      setAlert({
        open: true,
        severity: 'error',
        message: answer.error.message
      });
    } else {
      setUser({ ...user, photoURL: answer.url });
    }
  };

  const handlePictureDelete = async () => {
    const answer = await deleteProfilePicture(user.uid, user.photoURL);
    if (answer.error) {
      setAlert({
        open: true,
        severity: 'error',
        message: answer.error.message
      });
    } else {
      setUser({ ...user, photoURL: '' });
    }
  };

  return (
    <>
      <Box>
        <div className={classes.header}>
          <Typography variant='h5'>Allgemein</Typography>
        </div>
        <Paper elevation={0} variant='outlined' className={classes.userInfo}>
          <div className={classes.profile}>
            <div style={{ position: 'relative' }}>
              <Avatar
                className={classes.avatar}
                alt={user.displayName}
                src={user.photoURL}
              >
                {getInitials(user.displayName)}
              </Avatar>
              <label for='profilePicture' className={classes.editImage}>
                <EditRounded />
              </label>
              <input
                type='file'
                id='profilePicture'
                onChange={handlePictureChange}
                hidden
              />
              {user.photoURL && (
                <IconButton
                  size='small'
                  className={classes.deleteImage}
                  onClick={handlePictureDelete}
                >
                  <CloseRounded />
                </IconButton>
              )}
            </div>
            <div>
              {changeName.open ? (
                <div>
                  <TextField
                    value={changeName.value}
                    onChange={e =>
                      setChangeName({ ...changeName, value: e.target.value })
                    }
                    name='displayName'
                  />
                  <IconButton
                    size='small'
                    style={{ marginLeft: '5px' }}
                    onClick={handleNameChange}
                  >
                    <CheckCircleOutlineRounded />
                  </IconButton>
                </div>
              ) : (
                <div className={classes.flex}>
                  <Typography variant='h5'>{user.displayName}</Typography>
                  <IconButton
                    size='small'
                    style={{ marginLeft: '5px' }}
                    onClick={() => setChangeName({ ...changeName, open: true })}
                  >
                    <EditRounded className={classes.editButton} />
                  </IconButton>
                </div>
              )}
              <div className={classes.flex}>
                <Typography>{user.email}</Typography>
                <IconButton
                  size='small'
                  style={{ marginLeft: '5px' }}
                  onClick={() => setOpenEmailModal(true)}
                >
                  <EditRounded className={classes.editButton} />
                </IconButton>
              </div>
            </div>
          </div>
          <div>
            <Button
              className={classes.button}
              color='secondary'
              onClick={() => setOpenPwdModal(true)}
            >
              Passwort ändern
            </Button>
            <Button
              className={classes.button}
              color='secondary'
              onClick={handleResetPwd}
            >
              Passwort vergessen?
            </Button>
          </div>
          <Divider className={classes.divider} />
          <div>
            <Typography variant='h6'>Restaurants</Typography>
            <ul className={classes.restaurantList}>
              {user.restaurants?.map(r => (
                <li className={classes.restaurantLine}>
                  {r.id} <span className={classes.role}>({r.role})</span>
                </li>
              ))}
            </ul>
          </div>
        </Paper>
      </Box>
      <NewPasswordModal
        open={openPwdModal}
        onClose={() => setOpenPwdModal(false)}
        onSubmit={handleChangePassword}
        {...{ alert, setAlert }}
      />
      <ChangeEmailModal
        open={openEmailModal}
        onClose={() => setOpenEmailModal(false)}
        onSubmit={handleEmailChange}
      />
      <Snackbar
        open={alert.open}
        autoHideDuration={alert.severity === 'success' ? 2000 : 600000}
        onClose={() => setAlert(a => ({ ...a, open: false }))}
      >
        <Alert
          onClose={() => setAlert(a => ({ ...a, open: false }))}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GeneralAccountSettings;
