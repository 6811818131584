import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/messaging';

if(!firebase.apps || !firebase.apps.length) {
    firebase.initializeApp({
        apiKey: 'AIzaSyB7uzWXthIb7ErFGGia_aJUz4EPmvIpSZY',
        authDomain: 'schillingroofbarhd.firebaseapp.com',
        databaseURL: 'https://schillingroofbarhd.firebaseio.com',
        projectId: 'schillingroofbarhd',
        storageBucket: 'schillingroofbarhd.appspot.com',
        messagingSenderId: '313577808081',
        appId: '1:313577808081:web:693fae11afa38a00e3a5f0',
        measurementId: 'G-7YFWFP94KR'
      });
      
}

firebase.analytics();

export const db = firebase.firestore();

export const rtDB = firebase.database();
// const messaging = firebase.messaging();
export const FieldValue = firebase.firestore.FieldValue;

export default firebase;