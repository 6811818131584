import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CssBaseline, Hidden } from '@material-ui/core';
import { FirebaseContext } from 'Contexts/FirebaseContext';
import LeftNav from './LeftNav';
import GeneralAccountSettings from './GeneralAccountSettings';

const DRAWER_WIDTH = 256;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    maxWidth: '100vw'
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0
    }
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4)
  }
}));

const AccountFeature = ({ feature, ...props }) => {
  console.log(feature);
  switch (feature) {
    case 'general':
      return <GeneralAccountSettings {...props} />;
    default:
      return <div>No route here</div>;
  }
};

const AccountSettings = props => {
  const classes = useStyles();
  const { history, match } = props;
  const { data } = useContext(FirebaseContext);
  const { feature } = match.params;

  const [mobileOpen, setMobileOpen] = useState(false);
  const onDrawerToggle = () => setMobileOpen(!mobileOpen);

  return (
    <Box className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer}>
        <Hidden smUp implementation='js'>
          <LeftNav
            PaperProps={{
              style: {
                width: DRAWER_WIDTH,
                backgroundColor: 'var(--color-depth2)'
              }
            }}
            variant='temporary'
            open={mobileOpen}
            onClose={() => setMobileOpen(!mobileOpen)}
            history={history}
            match={match}
          />
        </Hidden>

        <Hidden xsDown implementation='css'>
          <LeftNav
            PaperProps={{
              style: {
                width: DRAWER_WIDTH,
                backgroundColor: 'var(--color-depth2)'
              }
            }}
            {...props}
          />
        </Hidden>
      </nav>

      <Box className={classes.app}>
        <AccountFeature
          {...{ ...props, feature, onDrawerToggle }}
          user={data.user}
        />
      </Box>
    </Box>
  );
};

export default AccountSettings;
