import React, { useState, useEffect, useContext } from 'react';
import { DashboardContext } from 'Contexts/DashboardContext';
import {
  Container,
  Typography,
  Box,
  IconButton,
  Button,
  Grid,
  Paper,
  List,
  TextField,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress
} from '@material-ui/core';
import { ArrowBack, Delete } from '@material-ui/icons';
import { FirebaseContext } from 'Contexts/FirebaseContext';

const Occassion = ({ match, history }) => {
  const { restaurantId } = match.params;

  const { db } = useContext(FirebaseContext);

  const { setcurrentRestaurant } = useContext(DashboardContext);

  const [occassions, setoccassions] = useState([]);

  const [loading, setloading] = useState(false);
  const [state, setstate] = useState('');

  useEffect(() => {
    setcurrentRestaurant(restaurantId);
  }, [restaurantId]);

  const handleSubmit = e => {
    e.preventDefault();
    let id = state.toLocaleLowerCase().replace(/\s/g, '-');

    if (!state) {
      return;
    }

    setoccassions(oldOccassions => [...oldOccassions, { id, title: state }]);

    setstate('');
  };

  const handleDelete = id => () => {
    setoccassions(oldOccassions => oldOccassions.filter(s => s.id !== id));
  };

  const next = async () => {
    try {
      if (loading) {
        return;
      }

      setloading(true);

      await db
        .collection('restaurants')
        .doc(restaurantId)
        .update({ occassions });

      await db
        .collection(`restaurants/${restaurantId}/settings`)
        .doc('reservations')
        .update({
          occassions
        });

      const spacesData = await db
        .collection('restaurantData')
        .doc(restaurantId)
        .get();

      if (!spacesData.exists) {
        throw new Error('Unbekannter Fehler');
      }

      let spaces = spacesData.data().spaces;

      await db
        .collection('restaurantData')
        .doc(restaurantId)
        .update({
          occassions,
          views: [
            {
              id: 'generell',
              name: 'Generell',
              filter: {
                occassion: occassions.map(o => o.id),
                space: spaces.map(s => s.id)
              },
              standardSpace: spaces[0].id
            }
          ]
        });

      console.log('Test');

      setloading(false);

      history.push(`/${restaurantId}/settings/general`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <IconButton
        onClick={() => history.push(`/${restaurantId}/all/home`)}
        style={{
          position: 'fixed',
          top: '1.5rem',
          left: '1.5rem',
          zIndex: 0
        }}
      >
        <ArrowBack />
      </IconButton>
      <Container>
        <Box style={{ margin: '1.5rem 0', zIndex: 1, position: 'relative' }}>
          <img src="/logo192.png" width="50px" height="50px" />
        </Box>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Typography
              color="textPrimary"
              variant="h1"
              style={{ margin: '2rem 0' }}
            >
              Arbeiten mit verschiedenen Anlässen
            </Typography>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              style={{ maxWidth: 600, margin: '1rem 0' }}
            >
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet.
            </Typography>

            <Paper
              variant="outlined"
              elevation={0}
              style={{
                padding: 10,
                maxWidth: 600
              }}
            >
              <Typography variant="h6">Neuen Anlass erstellen</Typography>
              <List>
                {occassions.map(space => (
                  <ListItem
                    key={space.id}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        {space.title.split(' ').map(w => w.charAt())}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={space.title} />
                    <ListItemSecondaryAction>
                      <IconButton onClick={handleDelete(space.id)}>
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <Box
                component="form"
                onSubmit={handleSubmit}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'baseline'
                }}
              >
                <TextField
                  value={state}
                  onChange={e => setstate(e.target.value)}
                  style={{ marginRight: 10, flex: 1 }}
                  placeholder="Neuer Anlass"
                />

                <Button
                  type="submit"
                  variant="contained"
                  disableElevation
                  color="primary"
                  size="small"
                >
                  Erstellen
                </Button>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}></Grid>
        </Grid>

        <Button
          color="primary"
          variant="contained"
          disableElevation
          style={{
            position: 'absolute',
            bottom: '1.5rem',
            right: '1.5rem',
            minWidth: 100
          }}
          onClick={next}
        >
          {loading && <CircularProgress color="inherit" size="16px" />}{' '}
          {!loading && 'Weiter'}
        </Button>
      </Container>
    </>
  );
};

export default Occassion;
