import React, { useContext, useState } from 'react';
import {
  Container,
  CssBaseline,
  Avatar,
  Typography,
  TextField,
  Button,
  Link,
  Box,
  Snackbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { VpnKeyRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { FirebaseContext } from 'Contexts/FirebaseContext';
import { Copyright } from './SignIn';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const ForgotPassword = props => {
  const classes = useStyles();
  const { sendPasswordResetEmail } = useContext(FirebaseContext);
  const [email, setEmail] = useState('');
  const [alert, setAlert] = useState({
    open: false,
    severity: 'error',
    message: 'Oops'
  });
  const [wrongEmail, setWrongEmail] = useState(false);

  const handleSendLink = () => {
    const emailRegex = new RegExp(
      /^[a-z0-9](?!.*?[^\na-z0-9]{2})[^\s@]+@[^\s@]+\.[^\s@]+[a-z0-9]$/
    );
    if (!emailRegex.test(email)) {
      setWrongEmail(true);
      return;
    }
    const answer = sendPasswordResetEmail('', email);
    console.log(answer);
    if (answer.error) {
      setAlert({
        open: true,
        severity: 'error',
        message: answer.error.message
      });
    } else {
      setAlert({
        open: true,
        severity: 'success',
        message: `A reset link has been sent to ${email}`
      });
      setTimeout(() => props.history.push('/signIn'), 4000);
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <VpnKeyRounded />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Passwort vergessen
        </Typography>
        <Typography variant='body'>
          Enter your email address and we will send you a link to reset your
          password.
        </Typography>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='email'
          label='Email'
          name='email'
          autoComplete='email'
          error={wrongEmail}
          helperText={wrongEmail ? 'Falsches E-Mail-Format' : ''}
          value={email}
          color='secondary'
          onChange={e => {
            setWrongEmail(false);
            setEmail(e.target.value);
          }}
          autoFocus
        />
        <Button
          fullWidth
          variant='contained'
          disableElevation
          className={classes.submit}
          color='secondary'
          onClick={handleSendLink}
        >
          Fortfahren
        </Button>
        <Link href='/signIn' variant='body2'>
          Zurück zur Anmeldeseite
        </Link>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <Snackbar
        open={alert.open}
        autoHideDuration={alert.severity === 'success' ? 4000 : 600000}
        onClose={() => setAlert(a => ({ ...a, open: false }))}
      >
        <Alert
          onClose={() => setAlert(a => ({ ...a, open: false }))}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ForgotPassword;
