import React, { useState, useEffect, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { CircularProgress } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { FirebaseContext } from 'Contexts/FirebaseContext';
import FacebookIcon from '@material-ui/icons/Facebook';
import GoogleIcon from './GoogleIcon';

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://www.gastronaut.ai/'>
        Gastronaut GmbH
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const GoogleButton = withStyles({
  root: {
    borderRadius: 2,
    fontSize: '14px',
    background: '#de5246',
    color: '#fff',
    boxShadow: '0 1px 1px 1px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      background: '#de5246',
      color: '#fff'
    }
  }
})(Button);

const FacebookButton = withStyles({
  root: {
    borderRadius: 2,
    fontSize: '14px',
    background: '#3b5998',
    color: '#fff',
    boxShadow: '0 1px 1px 1px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      background: '#3b5998',
      color: '#fff'
    }
  }
})(Button);

export default function SignIn({ history }) {
  const classes = useStyles();
  const { data, signInUser } = useContext(FirebaseContext);
  const [state, setstate] = useState({
    email: '',
    password: '',
    showPassword: false,
    loading: false
  });
  useEffect(() => {
    if (data.user) {
      history.push('/');
    }
    if (data.signInError) {
      setstate(st => ({ ...st, password: '' }));
    }
  }, [data.user, data.signInError]);

  const handleEmailSignIn = async e => {
    e.preventDefault();

    if (state.loading) return;

    setstate(st => ({ ...st, loading: true }));
    await signInUser('email', { email: state.email, password: state.password });
    setstate(st => ({ ...st, loading: false }));
  };

  const handleSocialMediaSignIn = async method => {
    if (state.loading) return;

    setstate(st => ({ ...st, loading: true }));
    await signInUser(method, {});
    setstate(st => ({ ...st, loading: false }));
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Anmelden
        </Typography>

        <Grid container justify='center'>
          <Grid item xs={12} style={{ marginBottom: 10, marginTop: 10 }}>
            <center>
              <GoogleButton
                onClick={() => handleSocialMediaSignIn('google')}
                fullWidth
                startIcon={<GoogleIcon />}
              >
                <Typography>Google</Typography>
              </GoogleButton>
            </center>
          </Grid>
          <br />

          <Grid item xs={12}>
            <center>
              <FacebookButton
                fullWidth
                onClick={() => handleSocialMediaSignIn('facebook')}
                startIcon={<FacebookIcon />}
              >
                <Typography>Facebook</Typography>
              </FacebookButton>
            </center>
          </Grid>
        </Grid>

        <form className={classes.form} noValidate onSubmit={handleEmailSignIn}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email'
            name='email'
            autoComplete='email'
            error={data.signInError && data.signInError.includes('email')}
            helperText={
              data.signInError &&
              data.signInError.includes('email') &&
              data.signInError
            }
            value={state.email}
            color='secondary'
            onChange={e => setstate({ ...state, email: e.target.value })}
            autoFocus
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Passwort'
            type='password'
            id='password'
            error={data.signInError && data.signInError.includes('password')}
            helperText={
              data.signInError &&
              data.signInError.includes('password') &&
              data.signInError
            }
            value={state.password}
            color='secondary'
            onChange={e => setstate({ ...state, password: e.target.value })}
            autoComplete='current-password'
          />
          <FormControlLabel
            control={<Checkbox value='remember' color='secondary' />}
            label='Angemeldet bleiben'
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            disableElevation
            className={classes.submit}
            color='secondary'
            onClick={handleEmailSignIn}
          >
            {state.loading ? (
              <CircularProgress size='18px' color='inherit' />
            ) : (
              'Sign In'
            )}
          </Button>

          <Grid container>
            <Grid item xs>
              <Link href='/forgotPassword' variant='body2'>
                Passwort vergessen?
              </Link>
            </Grid>
            <Grid item>
              <Link href='https://www.gastronaut.ai' variant='body2'>
                {'Werde Teil der Gastronauten'}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export { Copyright };
