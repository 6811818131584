import React, { lazy, useEffect, useState, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
// import Mobile from './App/Mobile/Mobile';
import FirebaseContextProvider from 'Contexts/FirebaseContext';
import ImageContextProvider from 'Contexts/ImageContext';
import DashboardContextProvider from 'Contexts/DashboardContext';
//import Dashboard from './App/Dashboard/Dashboard';
import SignIn from './App/Auth/SignIn';
import AccountSettings from './App/Auth/AccountSettings/AccountSettings';
import ForgotPassword from './App/Auth/ForgotPassword';
import Start from './App/Start';
import LoadingScreen from './App/Dashboard/LoadingScreen';
import EditViews from './App/Settings/EditViews';
// import ReservationBookV2 from './App/ReservationBook2/Index';
import ConfirmationDialog from './App/ConfirmationDialog';
import ThemeContextProvider from 'Contexts/ThemeContext';

import OnboardingStep1 from './App/Onboarding/Welcome';
import OnboardingStep2 from './App/Onboarding/Occassion';
import './Dashboard.css';

const mobile =
  (navigator.userAgent.includes('iPhone') ||
    navigator.userAgent.includes('Android')) &&
  window.innerWidth < 600;

const SettingsDashboard = lazy(() => import('./App/Settings/V02'));

const ReservationBookV2 = lazy(() => import('./App/ReservationBook2/Index'));

const Mobile = lazy(() => import('./App/Mobile/Mobile'));

const Dashboard = lazy(() => import('./App/Dashboard/Dashboard'));

const App = () => {
  // let timeout;

  // useEffect(() => {
  //   if (
  //     navigator.userAgent.includes('iPad') ||
  //     navigator.userAgent.includes('Android')
  //   ) {
  //     timeout = setTimeout(() => document.location.reload(), 180000);

  //     document.addEventListener('click', function() {
  //       clearTimeout(timeout);
  //       timeout = setTimeout(() => document.location.reload(), 180000);
  //     });
  //   }
  // }, []);

  return (
    <BrowserRouter>
      <ThemeContextProvider>
        <FirebaseContextProvider>
          <DashboardContextProvider>
            <ImageContextProvider>
              <Suspense fallback={<LoadingScreen />}>
                <Switch>
                  <Route path='/' component={Start} exact={true} />
                  <Route
                    path='/book/:restaurantId/:view/:date/:perspective'
                    component={!mobile ? ReservationBookV2 : Mobile}
                    exact={true}
                  />
                  <Route
                    path='/:restaurantId/settings/:feature'
                    component={SettingsDashboard}
                    exact={true}
                  />

                  <Route
                    path='/:restaurantId/onboarding/welcome'
                    component={OnboardingStep1}
                    exact={true}
                  />
                  <Route
                    path='/:restaurantId/onboarding/occassion'
                    component={OnboardingStep2}
                    exact={true}
                  />
                  <Route
                    path='/:restaurantId/:space/:feature'
                    component={!mobile ? Dashboard : Mobile}
                    exact={true}
                  />
                  <Route
                    path='/forgotPassword'
                    component={ForgotPassword}
                    exact={true}
                  />
                  <Route path='/signIn' component={SignIn} exact={true} />
                  <Route
                    path='/user-settings/:feature'
                    component={AccountSettings}
                    exact={true}
                  />
                </Switch>
              </Suspense>
              <EditViews />
              <ConfirmationDialog />
            </ImageContextProvider>
          </DashboardContextProvider>
          <LoadingScreen />
        </FirebaseContextProvider>
      </ThemeContextProvider>
    </BrowserRouter>
  );
};

export default App;
