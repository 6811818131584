import React, { useContext, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Input,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  FormHelperText
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FirebaseContext } from 'Contexts/FirebaseContext';

const ConfirmationDialog = () => {
  const { confirmationHandler, setConfirmationHandler } = useContext(
    FirebaseContext
  );

  const [showPassword, setshowPassword] = useState(false);
  const [repeatPassword, setrepeatPassword] = useState('');
  const [error, seterror] = useState(false);

  const {
    title,
    description,
    open,
    primaryAction,
    secondary,
    secondaryAction,
    closeAction = () => {},
    secondaryLabel,
    additionalValue,
    input,
    password,
    maxWidth = 'sm',
    deleteAction = false,
    deletePhrase = 'Wollen Sie dies wirklich löschen?',
    buttonLabel = ['Abbrechen', 'Fortfahren']
  } = confirmationHandler;

  const onClose = () => {
    if(closeAction) closeAction();
    setConfirmationHandler({ open: false });
  };

  const onSubmit = async () => {
    var val = input;
    if (!input && !!secondaryLabel) {
      val = secondary;
    }

    if (!password || (input === repeatPassword && input.length > 5)) {
      if (secondary && !!secondaryAction) {
        await secondaryAction(val);
      } else if (!!primaryAction) {
        await primaryAction(val);
      }
      setConfirmationHandler({ open: false });
    } else {
      seterror('Passwort stimmt nicht überein');
    }
  };

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  if (open) {
    return (
      <Dialog open={open} fullWidth maxWidth={maxWidth}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
          {!!additionalValue && (
            <FormControl error={error} style={{ width: '100%' }}>
              <InputLabel htmlFor="additionalValue">
                {additionalValue}
              </InputLabel>
              <Input
                value={input}
                id="additionalValue"
                fullWidth
                type={password && !showPassword ? 'password' : 'text'}
                onChange={e =>
                  setConfirmationHandler({
                    ...confirmationHandler,
                    input: e.target.value
                  })
                }
                endAdornment={
                  password && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </FormControl>
          )}

          {!!password && (
            <FormControl error={error} style={{ width: '100%' }}>
              <InputLabel htmlFor="repeatPassword">
                Passwort Wiederholen
              </InputLabel>
              <Input
                id="repeatPassword"
                value={repeatPassword}
                fullWidth
                type={password && !showPassword ? 'password' : 'text'}
                onChange={e => setrepeatPassword(e.target.value)}
                endAdornment={
                  password && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
              <FormHelperText>{error}</FormHelperText>
            </FormControl>
          )}

          {(!!secondaryAction || !!secondaryLabel) && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={secondary}
                  onChange={() =>
                    setConfirmationHandler({
                      ...confirmationHandler,
                      secondary: !secondary
                    })
                  }
                  value={secondary}
                />
              }
              label={secondaryLabel}
            />
          )}
        </DialogContent>
        <DialogActions style={{ padding: '16px 24px' }}>
          {!!primaryAction && (
            <Button color="primary" onClick={onClose}>
              {buttonLabel[0] || 'Abbrechen'}
            </Button>
          )}
          {!!deleteAction && (
            <Button
              color="primary"
              onClick={() => {
                let confirm = window.confirm(deletePhrase);
                if (confirm) deleteAction();
                onClose();
              }}
            >
              Löschen
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            disableElevation
            onClick={onSubmit}
            autoFocus
          >
            {!!primaryAction ? buttonLabel[1] || 'Fortfahren' : 'Okay'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return null;
  }
};

export default ConfirmationDialog;
