import React, { useState, useContext, useEffect } from 'react';
import { FirebaseContext } from 'Contexts/FirebaseContext';
import { CircularProgress } from '@material-ui/core';

const LoadingScreen = () => {
  const { data } = useContext(FirebaseContext);

  const hide =
    data.user ||
    document.location.pathname === '/signIn' ||
    document.location.pathname === '/forgotPassword';

  return (
    <div
      style={{
        display: hide ? 'none' : 'flex',
        zIndex: 10000,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        background: 'var(--color-depth1)'
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default LoadingScreen;
