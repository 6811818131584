import React, { useContext, useEffect, useState } from 'react';
import { ImageContext } from 'Contexts/ImageContext';
import {
  Chip,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  IconButton,
  Backdrop,
  Snackbar
} from '@material-ui/core';
import {
  AddRounded,
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  CloseRounded,
  CloudUploadRounded,
  DeleteRounded,
  FolderRounded,
  ImageRounded
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const Item = ({ id, name, type = 'Folder' }) => {
  const context = useContext(ImageContext);

  const isFolder = type === 'Folder';

  const isSelected = context[`current${type}`] === id;

  const handleClick = () => context[`setcurrent${type}`](id) || null;

  return (
    <ListItem
      button
      selected={isSelected}
      onClick={handleClick}
      dense
      style={{ maxWidth: '100%' }}
    >
      <ListItemIcon style={{ minWidth: 30 }}>
        {isFolder && <FolderRounded />}
        {!isFolder && <ImageRounded />}
      </ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  );
};

const ListContainer = ({ type = 'Folder', handleNewFolderClick }) => {
  const context = useContext(ImageContext);

  const dataSet = context[type.toLocaleLowerCase() + 's'] || {
    data: [],
    loading: true,
    error: null
  };

  const isFolder = type === 'Folder';

  return (
    <div
      style={{
        height: '100%',
        width: '25%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {/* Folders come here */}
      <div
        style={{
          flexGrow: 10,
          overflowY: 'scroll',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {!dataSet.loading &&
          !dataSet.error &&
          (isFolder || !!dataSet.data.length) && (
            <List style={{ height: '100%', width: '100%', padding: 0 }}>
              {isFolder && (
                <Item id='general' name='Alle Bilder' type='Folder' />
              )}
              {dataSet.data.map(item => (
                <Item
                  id={item.id}
                  name={isFolder ? item.name : item.originalName}
                  type={type}
                />
              ))}
            </List>
          )}

        {dataSet.loading && <CircularProgress />}
        {dataSet.error && (
          <div style={{ height: '100%', width: '100%' }}>
            <Alert severity='error'>{dataSet.error}</Alert>
          </div>
        )}
        {!isFolder &&
          !dataSet.error &&
          !dataSet.loading &&
          !dataSet.data.length && <div>Kein Bild gefunden</div>}
      </div>
      {/* Add Folder Button comes here */}
      {isFolder && (
        <Button onClick={handleNewFolderClick} startIcon={<AddRounded />}>
          Ordner hinzufügen
        </Button>
      )}
    </div>
  );
};

const ImageIcons = () => {
  const {
    modal,
    images: { data: images = [], loading, error },
    setcurrentImage,
    selectedImages,
    setselectedImages
  } = useContext(ImageContext);

  const handleImageClick = id => () => setcurrentImage(id);

  const toggleSelectedImages = id => e => {
    e.stopPropagation();
    setselectedImages(sI =>
      sI.includes(id) ? sI.filter(x => x !== id) : [...sI, id]
    );
  };

  const getclassName = id => {
    if (!modal || !modal.multiple) return '';

    if (selectedImages.includes(id)) {
      return 'multiple-image selected';
    } else {
      return 'multiple-image';
    }
  };

  const isSelected = id => selectedImages.includes(id);
  const isMultiple = modal && modal.multiple;

  return (
    <div style={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
      {!images.loading && !!images.length && (
        <div className='image-grid'>
          {images.map(img => (
            <div
              className={getclassName(img.id)}
              style={{
                display: 'flex',
                justifyItems: 'center',
                maxHeight: 150
              }}
              onClick={handleImageClick(img.id)}
            >
              {isMultiple && (
                <span
                  onClick={toggleSelectedImages(img.id)}
                  className='multiple-image-checkbox'
                >
                  {isSelected(img.id) ? (
                    <CheckBoxRounded />
                  ) : (
                    <CheckBoxOutlineBlankRounded />
                  )}
                </span>
              )}
              <div
                className='image'
                style={{ backgroundImage: `url("${img.url}")` }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const CurrentImage = () => {
  const {
    images: { data, ref },
    setcurrentImage,
    currentImage,
    deleteImage
  } = useContext(ImageContext);

  const [state, setstate] = useState({});
  const [deleteModal, setdeleteModal] = useState(false);

  const handleFieldChange = ({ target: { name, value } }) =>
    setstate(oV => ({ ...oV, [name]: value }));

  const removeTag = tag => () =>
    ref.doc(state.id).update({ tags: state.tags.filter(t => t !== tag) });

  const handleNewTag = e => {
    e.preventDefault();
    ref
      .doc(state.id)
      .update({ tags: [...new Set([...state.tags, state.newTag])] });
  };

  const handleBlur = () => {
    ref.doc(state.id).update({ alt: state.alt });
  };

  useEffect(() => {
    setstate(data.find(img => img.id === currentImage) || {});
    return () => {
      setstate({});
    };
  }, [data, currentImage]);

  const handleDeleteModalClose = () => setdeleteModal(false);

  const handleDelete = async () => {
    handleDeleteModalClose();
    await deleteImage(currentImage);
    setcurrentImage(null);
  };

  return (
    <div className='current-image'>
      <IconButton
        size='small'
        onClick={() => setcurrentImage(null)}
        className='close'
      >
        <CloseRounded size='small' />
      </IconButton>
      <div
        className='image'
        style={{ backgroundImage: `url("${state.url}")` }}
      />
      <div>
        <TextField
          value={state.alt}
          name='alt'
          label='Alt-Text'
          fullWidth
          onChange={handleFieldChange}
          onBlur={handleBlur}
        />

        <Typography
          variant='label'
          style={{ margin: '10px 0', display: 'block', fontSize: '80%' }}
        >
          Markierungen
        </Typography>

        <div>
          {state.tags &&
            state.tags.map(tag => (
              <Chip
                key={tag}
                label={tag}
                onDelete={removeTag(tag)}
                style={{ marginRight: 5, marginBottom: 5 }}
              />
            ))}
        </div>
        <form onSubmit={handleNewTag}>
          <TextField
            value={state.newTag}
            name='newTag'
            label='Neue Markierung'
            fullWidth
            onChange={handleFieldChange}
          />
        </form>
        <Button
          startIcon={<DeleteRounded />}
          style={{ marginTop: 5 }}
          onClick={() => setdeleteModal(true)}
        >
          Bild löschen
        </Button>
      </div>
      <Dialog
        open={deleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby='alert-dialog-title'
      >
        <DialogTitle id='alert-dialog-title'>
          Bild wird unwiederruflich gelöscht.
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteModalClose} color='primary'>
            Abbrechen
          </Button>
          <Button onClick={handleDelete} color='primary' autoFocus>
            Forfahren
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ImageModal = ({ open, onClose = () => {} }) => {
  const {
    modal,
    handleSubmit,
    selectedImages = [],
    createFolder,
    handleImageUpload,
    currentImage,
    imageLoading,
    imageError,
    setimageError
  } = useContext(ImageContext);

  const [newFolder, setnewFolder] = useState(null);

  const newFolderOpen = newFolder !== null;

  const closeNewFolder = () => setnewFolder(null);

  const handleNewFolderClick = () => setnewFolder('');

  const handleNewFolderChange = ({ target: { value = '' } }) =>
    setnewFolder(value);

  const handleNewFolderSubmit = () => {
    createFolder(newFolder);
    closeNewFolder();
  };

  const canBeSubmited = !!selectedImages.length || currentImage;

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='lg'
        open={open}
        onClose={onClose}
        aria-labelledby='image-dialog-title'
        id='ImageDialog'
      >
        <DialogTitle id='image-dialog-title'>
          <Typography>Bild Galerie</Typography>
          <Button component='label' startIcon={<CloudUploadRounded />}>
            Bild hochladen
            <input
              type='file'
              onChange={handleImageUpload}
              name='picture'
              accept='image/*'
              hidden
              multiple
            />
          </Button>
        </DialogTitle>
        <DialogContent>
          <div style={{ height: '50vh', width: '100%', display: 'flex' }}>
            <ListContainer
              type='Folder'
              handleNewFolderClick={handleNewFolderClick}
            />
            {imageLoading && (
              <Backdrop
                style={{ zIndex: '1000000', color: '#fff' }}
                open={imageLoading}
              >
                <CircularProgress color='primary' />
              </Backdrop>
            )}
            {currentImage && <ListContainer type='Image' />}
            <div
              style={{ height: '100%', flexGrow: 1, display: 'flex' }}
              id='imageIconOrCurrentImage'
            >
              {!currentImage ? <ImageIcons /> : <CurrentImage />}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Snackbar
            open={!!imageError}
            autoHideDuration={10000}
            onClose={() => setimageError(false)}
          >
            <Alert onClose={() => setimageError(false)} severity='error'>
              {imageError}
            </Alert>
          </Snackbar>
          <Button onClick={onClose} color='primary'>
            Abbrechen
          </Button>
          {!!modal && !!modal.onSubmit && (
            <Button
              onClick={handleSubmit}
              variant='contained'
              disableElevation
              disabled={!canBeSubmited}
              color='primary'
            >
              Auswählen
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth='sm'
        open={newFolderOpen}
        onClose={closeNewFolder}
        aria-labelledby='image-dialog-title'
      >
        <DialogTitle>Neuer Ordner</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            id='newFolder'
            label='Neuer Ordner'
            value={newFolder}
            onChange={handleNewFolderChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeNewFolder} color='primary'>
            Abbrechen
          </Button>
          <Button
            onClick={handleNewFolderSubmit}
            variant='contained'
            disableElevation
            disabled={!newFolder}
            color='primary'
          >
            Erstellen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImageModal;
