import React, { createContext, useState, useEffect, useMemo } from 'react';
import firebase from 'utils/firebase';
import { currentVersion } from './currentVersion.json';
import { publicEncrypt } from 'public-encrypt';

import useAudio from 'CustomHooks/useAudio';
import { dateHelper } from 'utils/helperFunctions';

const publicKey =
  '-----BEGIN PUBLIC KEY-----\n' +
  'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAty3cUCSzDUBKjLL7i5pl\n' +
  '0ixwmrobqwEDJ5b09lL/cGqrpczy2C+Q/NLb57Ixd4EF0J2YXTSIEx+x3Y6ud8g5\n' +
  'ccf/L11zC3gG4mrxnK2jUmjXSWKV6z5or1m/ojGc4y+Ybel4nrEundcOsw7RbFQ8\n' +
  'AKb1uQILOCj/YRFyHaG9A4OK6N7pXFtlHQfM7UsCGBblWkNxgZzePKQ6R+PytMU/\n' +
  'E4VBl3BOitLwhxfgRDXYDdjrZsvw0m+yYonWMnJtwybxvInXXl9eSqec68DVaX0O\n' +
  'KrsrzTqNC2bW/GFJ3HPQxDqtUct91SIPzHyR/m1ozbn+oPNRwAmKb4I844w4h/WQ\n' +
  'BwIDAQAB\n' +
  '-----END PUBLIC KEY-----\n';

// import {} from './businessLogic';

export const FirebaseContext = createContext();

// Initialize Cloud Firestore through Firebase

const db = firebase.firestore();
const rtDB = firebase.database();
const FieldValue = firebase.firestore.FieldValue;
const storageRef = firebase.storage().ref();
const dataUploadRef = firebase.storage();
const functions = firebase.app().functions('europe-west3');

window.currentVersion = currentVersion;

const isMessagingSupported =
  (navigator.userAgent.includes('Chrome') ||
    navigator.userAgent.includes('Android')) &&
  window.location.protocol.includes('https');

if (isMessagingSupported) {
  const messaging = firebase.messaging();
  messaging.onMessage((payload) => console.log('onMessage:', payload));
}
const FirebaseContextProvider = (props) => {
  const [data, setdata] = useState({
    loaded: false,
    exists: true,
    user: undefined
  });
  const [invitationState, setinvitationState] = useState({
    loading: true,
    error: null
  });
  const [checkGuest, setcheckGuest] = useState('');
  const [calendarSheets, setcalendarSheets] = useState([]);
  const [restaurantId, setrestaurantId] = useState(null);
  const [confirmationHandler, setConfirmationHandler] = useState({
    status: null
  });

  const [userId, setuserId] = useState(null);

  const signInUser = async (method, formdata) =>
    new Promise((resolve, reject) => {
      if (method === 'email') {
        const { email, password } = formdata;
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(() => resolve())
          .catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
            // console.log(errorCode, errorMessage);
            resolve();
            setdata({ ...data, signInError: errorMessage });
          });
      } else {
        let provider = null;

        switch (method) {
          case 'google':
            provider = new firebase.auth.GoogleAuthProvider();
            provider.addScope('email');
            break;
          case 'facebook':
            provider = new firebase.auth.FacebookAuthProvider();
            provider.addScope('email');
            break;
          default:
            break;
        }

        firebase
          .auth()
          .signInWithPopup(provider)
          .then((res) => {
            console.log('resolved');
            console.log(res);
            resolve();
          })
          .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
            console.log(errorCode, errorMessage);
            resolve();
            setdata({ ...data, signInError: errorMessage });
          });
      }
    });

  const signOutUser = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        setdata({ ...data, user: false });
      })
      .catch(function (error) {
        // console.log(error)
      });
  };

  useEffect(() => {
    if (data.user === undefined) {
      var user = firebase.auth().currentUser;

      if (user) {
        // User is signed in.
        (async () => {
          var displayName = user.displayName;
          var email = user.email;
          var emailVerified = user.emailVerified;
          var photoURL = user.photoURL;
          var isAnonymous = user.isAnonymous;
          var uid = user.uid;
          var providerData = user.providerData;

          window.uid = uid;

          const userRef = await db.collection('users').doc(uid).get();

          if (!userRef.exists) {
            setdata({ ...data, user: false });
            signOutUser();
            return;
          }

          if (!userRef.data().lastActive) {
            confirmationDialogHandler(
              {
                title: 'Willkommen bei Gastronaut',
                description: `Wollen Sie Ihr Passwort ändern?`,
                additionalValue: 'Neues Passwort'
              },
              (newPwd) => {
                let encryptedPwd = publicEncrypt(
                  publicKey,
                  Buffer.from(newPwd)
                );

                db.collection('editUsers').add({
                  id: uid,
                  encryptedPwd,
                  type: 'changePassword',
                  restaurant: userRef.data().restaurant
                });
              },
              () => {
                db.collection('users')
                  .doc(uid)
                  .update({ lastActive: FieldValue.serverTimestamp() });
              }
            );
          }

          // if (false) {
          //   const messaging = firebase.messaging();

          //   messaging
          //     .requestPermission()
          //     .then(() => messaging.getToken())
          //     .then(token => {
          //       db.collection('users').doc(uid).update({
          //         lastActive: FieldValue.serverTimestamp(),
          //         messagingToken: token
          //       });

          //       console.log('Token:', token);
          //     })
          //     .catch(error => console.error(error.message));
          // }

          window.userData = {
            displayName,
            email,
            emailVerified,
            photoURL,
            isAnonymous,
            uid,
            providerData,
            ...userRef.data()
          };

          setdata({
            ...data,
            user: {
              displayName,
              email,
              emailVerified,
              photoURL,
              isAnonymous,
              uid,
              providerData,
              ...userRef.data()
            },
            signInError: false
          });
        })();
      }
    }
  }, [data.user]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        (async () => {
          var displayName = user.displayName;
          var email = user.email;
          var emailVerified = user.emailVerified;
          var photoURL = user.photoURL;
          var isAnonymous = user.isAnonymous;
          var uid = user.uid;
          var providerData = user.providerData;

          window.uid = uid;
          setuserId(uid);

          const userRef = await db.collection('users').doc(uid).get();

          if (!userRef.exists) {
            console.log('test got triggered');
            setdata({ ...data, user: false, notLoggedIn: true });
            signOutUser();
            return;
          }

          if (
            !userRef.data().lastActive &&
            providerData[0].providerId !== 'google.com' &&
            providerData[0].providerId !== 'facebook.com'
          ) {
            console.log('userRef', user);
            confirmationDialogHandler(
              {
                title: 'Willkommen bei Gastronaut',
                description: `Wollen Sie Ihr Passwort ändern?`,
                additionalValue: 'Neues Passwort',
                password: true
              },
              (newPwd) => {
                let encryptedPwd = publicEncrypt(
                  publicKey,
                  Buffer.from(newPwd)
                );

                db.collection('editUsers').add({
                  id: uid,
                  encryptedPwd,
                  type: 'changePassword',
                  restaurant: userRef.data().restaurant
                });
              },
              () => {
                db.collection('users')
                  .doc(uid)
                  .update({ lastActive: FieldValue.serverTimestamp() });
              }
            );
          }

          // if (false) {
          //   const messaging = firebase.messaging();

          //   messaging
          //     .requestPermission()
          //     .then(() => messaging.getToken())
          //     .then(token => {
          //       db.collection('users').doc(uid).update({
          //         lastActive: FieldValue.serverTimestamp(),
          //         messagingToken: token
          //       });

          //       console.log('Token:', token);
          //     })
          //     .catch(error => console.error(error.message));
          // }

          window.userData = {
            displayName,
            email,
            emailVerified,
            photoURL,
            isAnonymous,
            uid,
            providerData,
            ...userRef.data()
          };

          setdata({
            ...data,
            user: {
              displayName,
              email,
              emailVerified,
              photoURL,
              isAnonymous,
              uid,
              providerData,
              ...userRef.data()
            },
            signInError: false
          });
        })();
      } else {
        // User is signed out.
        setdata({ ...data, user: false, notLoggedIn: true });

        if (
          document.location.pathname !== '/signIn' &&
          !document.location.pathname.includes('invitation') &&
          !document.location.pathname.includes('sendRequest') &&
          document.location.pathname !== '/forgotPassword'
        ) {
          document.location.replace('/signIn');
        }
      }
    });
  }, []);

  const confirmationDialogHandler = (
    data,
    primaryAction,
    secondaryAction = null,
    closeAction = () => {}
  ) => {
    var secondary = !!secondaryAction;

    setConfirmationHandler({
      secondary,
      closeAction,
      ...data,
      open: true,
      status: null,
      primaryAction,
      secondaryAction
    });
  };

  const signUpWaiter = async (method, formData = {}) => {
    try {
      if (!method) throw new Error('Choose a Method');

      if (method === 'email') {
        let { email, password, name } = formData;

        const {
          user: { uid }
        } = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password);

        await db
          .collection(
            `restaurants/${invitationState.restaurantId}/pendingInvitations`
          )
          .doc(uid)
          .set({ name, status: 'pending', createdAt: Date.now() });

        setinvitationState((cV) => ({ ...cV, uid, displayName: name }));

        return {};
      } else {
        let provider = null;

        switch (method) {
          case 'google':
            provider = new firebase.auth.GoogleAuthProvider();
            break;
          case 'facebook':
            provider = new firebase.auth.FacebookAuthProvider();
            break;
          default:
            break;
        }

        const {
          user: { uid, displayName }
        } = await firebase.auth().signInWithPopup(provider);

        setinvitationState((cV) => ({ ...cV, uid, displayName }));

        return {};
      }
    } catch (error) {
      console.error(error.message);
      return { error };
    }
  };

  const changeUserPassword = async (userId, userEmail, oldPwd, newPwd) => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser.uid !== userId) {
      return { error: 'Current user error' };
    }
    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        userEmail,
        oldPwd
      );
      await currentUser.reauthenticateWithCredential(credential);
      await currentUser.updatePassword(newPwd);
      return { success: 'Password changed' };
    } catch (error) {
      console.error(error.message);
      return { error };
    }
  };

  const changeUserEmail = async (userId, oldEmail, userPwd, newEmail) => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser.uid !== userId) {
      return { error: 'Current user error' };
    }
    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        oldEmail,
        userPwd
      );
      await currentUser.reauthenticateWithCredential(credential);
      //Update in firebase auth
      await currentUser.updateEmail(newEmail);

      //Find and update in the db
      const userRef = db.collection('users').doc(userId);
      const userDoc = await userRef.get();
      if (!userDoc.exists) {
        return { error: 'User not in the general DB' };
      }
      const restaurants = [...userDoc.data().restaurants];

      let batch = db.batch();
      restaurants.forEach((r) => {
        let ref = db.collection(`restaurants/${r.id}/users`).doc(userId);
        batch.update(ref, { email: newEmail });
      });
      await batch.commit();

      return { success: 'Email changed' };
    } catch (error) {
      console.error(error.message);
      return { error };
    }
  };

  const changeUserDisplayName = async (userId, newName) => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser.uid !== userId) {
      return { error: 'Current user error' };
    }
    try {
      //Update in firebase auth
      await currentUser.updateProfile({ displayName: newName });

      //Find and update the user doc in users collection in db
      const userRef = db.collection('users').doc(userId);
      const userDoc = await userRef.get();
      if (!userDoc.exists) {
        return { error: 'User not in the general DB' };
      }
      const restaurants = [...userDoc.data().restaurants];
      const newRestaurants = restaurants.map((r) => {
        r.name = newName;
        return r;
      });
      await userRef.update({
        displayName: newName,
        restaurants: newRestaurants
      });

      // Find and update the user doc in every restaurant collection
      let batch = db.batch();
      restaurants.forEach((r) => {
        let ref = db.collection(`restaurants/${r.id}/users`).doc(userId);
        batch.update(ref, { name: newName });
      });
      await batch.commit();

      return { success: 'Name changed' };
    } catch (error) {
      console.error(error.message);
      return { error };
    }
  };

  const sendPasswordResetEmail = async (
    userId,
    userEmail,
    fromLoginUser = false
  ) => {
    const currentUser = firebase.auth().currentUser;
    if (fromLoginUser && currentUser.uid !== userId) {
      return { error: 'Current user error' };
    }
    if (fromLoginUser && currentUser.email !== userEmail) {
      return { error: 'Current user email error' };
    }
    try {
      await firebase.auth().sendPasswordResetEmail(userEmail);
      return { success: 'Email sent' };
    } catch (error) {
      console.error(error.message);
      return { error };
    }
  };

  const uploadProfilePicture = async (image, userId) => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser.uid !== userId) {
      return { error: 'Current user error' };
    }
    try {
      let { name: imageName } = image;

      imageName = userId + '_' + imageName;

      const reference = `/user/${userId}/${imageName}`;

      const fileRef = storageRef.child(reference);

      await fileRef.put(image);

      const url = await fileRef.getDownloadURL();

      await currentUser.updateProfile({ photoURL: url });

      return { url };
    } catch (error) {
      return { error };
    }
  };

  const deleteProfilePicture = async (userId, imageURL) => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser.uid !== userId) {
      return { error: 'Current user error' };
    }
    console.log('image', imageURL);
    try {
      const httpsReference = dataUploadRef.refFromURL(imageURL);
      await httpsReference.delete();
      await currentUser.updateProfile({ photoURL: '' });
      console.log('success');
      return { success: 'image deleted' };
    } catch (error) {
      console.error(error.message);
      return { error };
    }
  };

  const [playAlarmSound, stopAlarmSound] = useAudio(
    '/alarm.mp3',
    confirmationDialogHandler,
    restaurantId
  );
  const [takeAwayListener, setTakeAwayListener] = useState([]);

  useEffect(() => {
    if (takeAwayListener.length) {
      takeAwayListener.forEach((unsubscribe) => unsubscribe());
    }

    stopAlarmSound('all');

    console.log({ restaurantId });

    if (restaurantId) {
      const ref = db
        .collection(`restaurants/${restaurantId}/takeAwayOrders`)
        .where('date', '==', dateHelper());

      console.log(dateHelper());

      let unsubscribe = ref.onSnapshot(
        (responses) => {
          console.log(responses);

          responses.forEach((doc) => {
            if (doc.data().status === 'pending' && !doc.data().canceled) {
              console.log('SHOULD PLAY SOUND');
              playAlarmSound(doc.id);
            } else {
              stopAlarmSound(doc.id);
            }
          });
        },
        (error) => {
          console.error(error);
        }
      );

      setTakeAwayListener([unsubscribe]);
    }

    return () => {
      if (takeAwayListener.length) {
        takeAwayListener.forEach((unsubscribe) => unsubscribe());
      }
    };
  }, [restaurantId]);

  return (
    <FirebaseContext.Provider
      value={{
        // Firebase
        db,
        rtDB,
        dataUploadRef,
        FieldValue,
        storageRef,
        functions,
        // User
        signInUser,
        signOutUser,
        data,
        isAdmin: data.user && data.user.role === 'admin',
        setdata,
        changeUserPassword,
        sendPasswordResetEmail,
        changeUserDisplayName,
        changeUserEmail,
        uploadProfilePicture,
        deleteProfilePicture,
        // Check Guest @TODO: Whats that
        checkGuest,
        setcheckGuest,
        // Confirmation Dialog
        confirmationDialogHandler,
        confirmationHandler,
        setConfirmationHandler,
        //Calendar Sheets
        calendarSheets,
        setcalendarSheets,
        restaurantId,
        setrestaurantId,
        signUpWaiter,
        invitationState,
        setinvitationState,
        userId
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseContextProvider;
